<template>
  <div class="resto_livraison adjust_width">
    <title_cat>Livraison / retrait</title_cat>
    
    <div class="check_box">
      <div class="left">
        <div class="t">Activer le retrait</div>
        <div class="p">Permets à vos clients de venir récupérer leur commande lorsque celle ci est prête. <br>
          Activez vos créneaux de retrait en cliquant sur le bouton ci-dessous.
        </div>
        <router-link tag="button" :to="{ name: 'bo_config_horaires' }" class="simple">Vos horaires</router-link>
      </div>
      <el-switch class="color red_blink" :disabled="$parent.boutique.boutique.horaires_click_n_collect_empty" @change="edit('retrait_active')" v-model="$parent.boutique.boutique.retrait_active"></el-switch>

    </div>
    <div class="check_box">
      <div class="left">
        <div class="t">Activer la livraison</div>
        <div class="p">Si vous avez une équipe de livraison, vous pouvez définir les villes desservies.</div>
        
        <label>Zones de livraison</label>
        <div class="villes">
          <el-select style="margin-bottom: 6px;" multiple placeholder="Définir les villes éligibles à la livraison" v-model="$parent.boutique.boutique.livraison_villes" @change="edit('livraison_villes')">
            <el-option
                v-for="item in city_arround"
                :key="item.ville.code"
                :label="item.ville.nom"
                :value="item.ville.code">
              <span style="float: left">{{ item.ville.nom }}</span>
              <span style="float: right; padding-right: 24px; color: var(--black-sub); font-size: 13px">{{ item.distance | formatNumber }} km</span>
            </el-option>
          </el-select>

        </div>
  
        <div class="left_border" v-if="$parent.boutique.boutique.livraison_villes && $parent.boutique.boutique.livraison_villes.length">
        <label class="big" style="padding-top: 0;">Tarif par villes</label>
        <div class="prices">
          <div class="price" v-for="n in $parent.boutique.boutique.livraison_villes" :key="n">
            <label>{{ couvert_code_ville(n) }}</label>
            <el-input-number placeholder="0.00€" @change="livraison_prices_edit" v-model="livraison_prices[n]" :precision="2" :step="0.05" :min="0" :max="10"></el-input-number>
          </div>
        </div>
        </div>
  
        <div class="left_border" style="margin-top: 32px; margin-bottom: 36px" v-if="$parent.boutique.boutique.livraison_villes && $parent.boutique.boutique.livraison_villes.length">
        <label class="big" style="padding-top: 0;">Montant minimum par villes</label>
        <div class="prices">
          <div class="price" v-for="n in $parent.boutique.boutique.livraison_villes" :key="n">
            <label>{{ couvert_code_ville(n) }}</label>
            <el-input-number placeholder="0.00€" @change="livraison_min_price_edit" v-model="livraison_min_price[n]" :precision="2" :step="0.5" :min="0" :max="30"></el-input-number>
          </div>
        </div>
        </div>

<!--        <label style="padding-top: 16px;">Montant minimum par commande</label>-->
<!--        <el-input-number placeholder="0.00€" style="width: 180px!important; padding-bottom: 16px;" v-model="$parent.boutique.boutique.livraison_min" @change="edit('livraison_min')" :min="0"></el-input-number>-->
<!--        <el-input type="number" style="width: 140px; padding-bottom: 16px;" v-model="$parent.boutique.boutique.livraison_min" @change="edit('livraison_min')" :min="0" placeholder="0.00€"></el-input>-->
        
      </div>
      <el-switch class="color red_blink" :disabled="!$parent.boutique.boutique.livraison_villes || !$parent.boutique.boutique.livraison_villes.length" @change="edit('livraison_active')" v-model="$parent.boutique.boutique.livraison_active"></el-switch>
    </div>
    
    
  </div>
</template>

<script>
export default {
  name: "resto_livraison",
  data() {
    return {
      city_arround: null,
      
      livraison_prices: {},
      livraison_min_price: {}
    }
  },
  components: {
    
  },
  created() {
    this.$http.get('/geo/city/arround/' + this.$parent.boutique.boutique.geo.lng + '/' + this.$parent.boutique.boutique.geo.lat).then((cities) => {
      this.city_arround = cities.data
    })
    if(this.$parent.boutique.boutique.livraison_prices)
      this.livraison_prices = this.$parent.boutique.boutique.livraison_prices
    
    if(this.$parent.boutique.boutique.livraison_min_price)
      this.livraison_min_price = this.$parent.boutique.boutique.livraison_min_price
  },
  watch: {
    getBoutique() {
      if(this.$parent.boutique.boutique.livraison_prices)
        this.livraison_prices = this.$parent.boutique.boutique.livraison_prices
      this.$forceUpdate()
      if(this.$parent.boutique.boutique.horaires_click_n_collect_empty) {
        this.$parent.boutique.boutique.retrait_active = false
        this.edit('retrait_active')
      }
    }
  },
  computed: {
    getBoutique() {
      return this.$parent.boutique.boutique.horaires_click_n_collect_empty
    }
  },
  methods: {
    livraison_prices_edit() {
      if(this.livraison_prices) {
        this.$nextTick(() => {
          this.livraison_prices_format()
          this.$parent.boutique.boutique.livraison_prices = this.livraison_prices
          this.edit('livraison_prices')
        })
      }
    },
    livraison_prices_format() {
        for (let n in this.livraison_prices) {
          if (!this.livraison_prices[n]) delete this.livraison_prices[n]
          else this.livraison_prices[n] = parseFloat(this.livraison_prices[n])
          this.$forceUpdate()
        }
    },
    livraison_min_price_edit() {
      if(this.livraison_min_price) {
        this.$nextTick(() => {
          this.livraison_min_price_format()
          this.$parent.boutique.boutique.livraison_min_price = this.livraison_min_price
          this.edit('livraison_min_price')
        })
      }
    },
    livraison_min_price_format() {
      for (let n in this.livraison_min_price) {
        if (!this.livraison_min_price[n]) delete this.livraison_min_price[n]
        else this.livraison_min_price[n] = parseFloat(this.livraison_min_price[n])
        this.$forceUpdate()
      }
    },
    couvert_code_ville(code) {
      for(let n of this.city_arround) {
        if(n.ville.code === code) {
          return n.ville.nom
        }
      }
    },
    edit(key) {
      if(key === 'livraison_min' && !this.$parent.boutique.boutique.livraison_min)
        this.$parent.boutique.boutique.livraison_min = 0
      
      if(key === 'livraison_villes' && !this.$parent.boutique.boutique.livraison_villes.length) {
        this.$parent.boutique.boutique.livraison_active = false
        this.edit('livraison_active')
      }
      // this.$parent.boutique.retrait_active = !this.$parent.boutique.retrait_active
      this.$http.post('/bo/boutique/edit', {
        key: key,
        value: this.$parent.boutique.boutique[key]
      }).then(() => {
        this.$store.dispatch("boutique_bo/sync");
      })
    }
  }
}
</script>

<style scoped>
  .left_border { border-left: 1px solid #dcdcdc; padding-left: 16px; }
  .left_border label.big { font-weight: 500; color: var(--black-main) }

  .check_box { display: flex; justify-content: space-between; border-bottom: 1px solid var(--gris); padding-top: 16px; }
  .check_box:nth-child(2) { padding-top: 0; }
  .check_box .t { font-size: 16px; }
  .check_box .p { font-size: 14px; color: var(--black-sub); padding-top: 10px; font-weight: 400; padding-bottom: 16px; }
  .check_box button { margin-bottom: 16px; margin-top: 0; }
  .check_box .villes { padding-bottom: 16px; }
  
  .prices { }
  .prices .price { display: flex; align-items: center; margin-bottom: 16px; }
  .prices .price label { flex: 0 0 auto; margin-right: 16px; padding: 0; min-width: 80px; }
  .prices .price .el-input-number { width: 180px!important; }
</style>